@use 'styles/variables' as *;

.serviceFlowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
  padding: var(--spacing-4xl);
  background-color: var(--color-gray-lightest);

  @media screen and (max-width: $breakpoint-m) {
    flex-direction: column;
    height: auto;
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: var(--spacing-xl) var(--spacing-s);
  }
}

.gridContainer {
  display: flex;
  max-width: 75rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: $breakpoint-m) {
    flex-direction: column;
    align-items: center;
    padding-top: var(--spacing-l);
    min-height: 35vh;
  }
}

.leftColumn {
  flex: 1;
  max-width: 40%;
  padding-right: var(--spacing-3xl);

  @media screen and (max-width: $breakpoint-m) {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: var(--spacing-4xl);
    text-align: center;
  }
}

.rightColumn {
  flex: 1;
  max-width: 60%;

  @media screen and (max-width: $breakpoint-m) {
    max-width: 90%;
  }
}

.title {
  font-size: var(--heading-font-size-standard);
  font-weight: var(--font-weight-standard);
  color: var(--color-gray-darker);

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--heading-font-size-small);
    text-align: center;
    margin-bottom: var(--spacing-l);
  }

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--heading-font-size-small);
  }
}

.faqContainer {
  font-size: var(--text-font-size-standard);

  .faq-row-wrapper {
    border-bottom: var(--border-standard) var(--color-gray-light);
    margin-bottom: var(--spacing-s);
  }

  .faq-row-title {
    font-size: var(--text-font-size-large);
    font-weight: var(--font-weight-semibold);
    padding: var(--spacing-l) 0;
    color: var(--color-gray-darker);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }

  .faq-row-content {
    padding: 0 var(--spacing-l);
    color: var(--color-gray-standard);

    &.open {
      padding-bottom: var(--spacing-l);
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--text-font-size-small);

    .faq-row-title {
      font-size: 1.1rem;
      padding: var(--spacing-m) 0;
    }

    .faq-row-content {
      font-size: 0.95rem;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    .faq-row-title {
      font-size: 1rem;
    }

    .faq-row-content {
      font-size: 0.9rem;
    }

    .faq-row-wrapper {
      margin-bottom: var(--spacing-m);
    }
  }
}
