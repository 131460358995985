@use 'styles/variables' as *;

$topMargin: calc(var(--spacing-l) + var(--header-height) + var(--spacing-vertical-standard) * 2);
$topMarginSmall: calc(var(--spacing-vertical-standard) * 2);

.header {
  margin-top: calc($topMargin * -1);
  @media screen and (max-width: $breakpoint-m) {
    margin-top: calc($topMarginSmall * -1);
  }
}

.content {
  padding-top: $topMargin;
  padding-bottom: var(--spacing-2xl);
  margin-bottom: var(--spacing-2xl);
  @media screen and (max-width: $breakpoint-m) {
    padding-top: $topMarginSmall;
  }
  @media screen and (max-width: $breakpoint-s) {
    padding-bottom: var(--spacing-l);
    margin-bottom: var(--spacing-xl);
  }
}
