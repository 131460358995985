@use '../../../styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }

  span {
    color: var(--color-gray-darker);
    font-weight: var(--font-weight-bold);
    font-size: var(--text-font-size-large);
    width: max-content;
  }

  a {
    margin-top: 0.5rem;
    color: inherit;
    position: relative;
    display: flex;
  }

  .arrow {
    width: 20px;
    height: 20px;
    border-radius: var(--rounded-full);
    margin-left: 0.5rem;
    align-self: center;
  }
}
