@use './variables' as *;

@mixin color($property) {
  @each $name, $color in $colors {
    &-#{$name} {
      #{$property}: $color;
    }
  }
}

.text {
  @include color('color');
}

.bg {
  @include color('background-color');
}

.border {
  @include color('border-color');
}
