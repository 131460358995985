@use 'styles/variables' as *;

.faqList {
  display: flex;
  flex-direction: column;
}

.faqButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f5f5f5;
  }
}

@media screen and (max-width: $breakpoint-m) {
  .faqList {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (min-width: $breakpoint-m) {
  .faqList {
    width: 80%;
  }
}
