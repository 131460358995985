@use './variables' as *;

a,
.link {
  color: var(--color-blue-dark);
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap;
  transition-property: opacity;
  transition-duration: 0.25s;
  cursor: pointer;

  &:visited {
    color: var(--color-blue-dark);
  }

  &:hover:not(.button),
  &:hover:not(.not-link) {
    text-decoration: underline;
  }
}
