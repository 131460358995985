@use '../../../styles/variables' as *;

// NOTE: Currently CSS variable definitions are under a bit of reconstruction
// TODO: Replace pixel values with variables once new variables are in place
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.label {
  margin-bottom: var(--spacing-xs);
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-font-size-standard);
  color: var(--color-gray-darker);
}
.large .label {
  margin-bottom: var(--spacing-s);
  font-size: var(--text-font-size-large);
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--input-standard-border-radius);
}
.inputAfter {
  padding-right: 1.25rem;
}
.inputAfterText {
  position: absolute;
  right: var(--spacing-m);
  color: var(--color-gray-standard);
  font-size: var(--text-font-size-standard);
}
.large .inputAfterText {
  right: var(--spacing-l);
  font-size: var(--text-font-size-large);
}

.endAdornmentIcon {
  position: absolute;
  right: var(--spacing-xs);
  color: var(--color-gray-standard);
  width: var(--icon-size-medium);
  height: var(--icon-size-medium);
}
.large .endAdornmentIcon {
  right: var(--spacing-s);
  width: var(--icon-size-large);
  height: var(--icon-size-large);
}

.field {
  background-color: transparent;
  z-index: 1;
  height: var(--input-height-standard);
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0;
  padding: 0 var(--input-standard-padding-horisontal);
  border-radius: var(--input-standard-border-radius);
  appearance: none;
  border: none;
  outline: none;
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-font-size-standard);
  border: var(--input-standard-border-width) var(--color-gray-standard);
  color: var(--color-gray-darker);

  &.large {
    height: var(--input-height-large);
    padding: 0 var(--input-large-padding-horisontal);
    border-radius: var(--input-large-border-radius);
    font-size: var(--text-font-size-large);
    border: var(--input-large-border-width) var(--color-gray-standard);
  }

  &:active,
  &:focus {
    border-color: var(--color-blue-standard);
  }

  &.invalid {
    border-color: var(--color-red-standard);
    &:focus {
      outline: var(--border-standard) var(--color-red-light);
    }
  }
  &.disabled {
    background-color: var(--color-gray-lightest);
    border-color: var(--color-gray-light);
    color: var(--color-gray-standard);
  }
}

textarea.field {
  padding: var(--spacing-s) var(--spacing-l);
  min-height: 5rem;
  font-size: var(--text-font-size-standard);
  height: auto;
  &.large {
    padding: var(--spacing-m) var(--spacing-l);
    font-size: var(--text-font-size-large);
    height: auto;
  }
}

.error {
  display: flex;
  align-items: center;
  margin-top: var(--input-standard-error-margin);
  font-weight: var(--font-weight-bold);
  font-size: var(--input-standard-error-text-font-size);
  color: var(--color-red-standard);

  .svgError,
  svg,
  img {
    width: var(--input-standard-error-icon-size);
    height: var(--input-standard-error-icon-size);
    margin-right: var(--input-standard-error-margin);
  }
}
.helpertext {
  display: flex;
  align-items: center;
  margin-top: var(--input-standard-error-margin);
  font-weight: var(--font-weight-standard);
  font-size: var(--input-standard-error-text-font-size);
}

.large .helpertext,
.large .error {
  font-size: var(--input-large-error-text-font-size);
  margin-top: var(--input-large-error-margin);
  .svgError {
    width: var(--input-large-error-icon-size);
    height: var(--input-large-error-icon-size);
    margin-right: var(--input-large-error-margin);
  }
}
