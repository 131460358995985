@use '../../../../styles/variables' as *;

.header {
  padding: 0 0 var(--spacing-l) 0;
  border-bottom: var(--border);
}

.banner {
  background-color: var(--color-blue-dark);
  color: #fff;
  flex-direction: row;
  display: flex;
  gap: var(--cards-modals-gap);
  padding: var(--cards-modals-padding);
  overflow: hidden;
  justify-content: space-between;

  .content {
    display: flex;
    flex-shrink: 0;
    width: 50%;
    min-width: 50%;
    .innerContent {
      grid-column: span 5;
    }
  }
  .imageWrapper {
    flex-shrink: 1;
    margin-top: calc(var(--cards-modals-padding) * -1);
    margin-bottom: calc(var(--cards-modals-padding) * -1);
    justify-content: flex-end;
    img {
      min-height: 100%;
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: $breakpoint-s) {
  .banner {
    .content {
      width: 100% !important;
    }
    .imageWrapper {
      display: none !important;
    }
  }
}
