@use 'styles/variables' as *;

.background {
  position: fixed;
  overflow: auto;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#070707, 0.56);
  @media scre1en and (max-width: $breakpoint-xs) {
    padding: 0;
  }
}

@media screen and (min-width: $breakpoint-xs) {
  .container {
    display: flex;
    width: 60%;
    flex-direction: column;
    margin: var(--spacing-3xl) auto;
    padding: var(--cards-modals-padding);
    max-width: 40rem;
    border-radius: var(--rounded);
    background-color: white;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: var(--spacing-2xl);

      .title {
        font-size: var(--heading-font-size-standard);
        font-family: $font-family-cocon-regular;
        color: var(--color-red-standard);
      }
      .closebutton {
        svg {
          width: var(--icon-size-standard);
          height: var(--icon-size-standard);
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .container {
    justify-content: center;
    border-radius: 0;
    margin: 0;
    display: flex;
    max-height: 100vh;
    width: 85%;
    flex-direction: column;
    margin: var(--spacing-2xl) auto;
    padding: 1.12rem;
    border-radius: var(--rounded);
    background-color: white;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-2xl);

    .title {
      font-size: var(--heading-font-size-small);
      font-family: $font-family-cocon-regular;
      color: var(--color-red-standard);
    }
    .closebutton {
      svg {
        width: var(--icon-size-large);
        height: var(--icon-size-large);
      }
    }
  }
}
