@use './variables' as *;

.heading-standard,
h2,
h3 {
  font-family: $font-family-cocon-regular;
  font-size: var(--heading-font-size-standard);
  margin-bottom: var(--heading-margin-standard);
  line-height: var(--heading-line-height);
  font-weight: 400;
}

h1,
.heading-large {
  font-family: $font-family-cocon-bold;
  font-size: var(--heading-font-size-large);
  margin-bottom: var(--heading-margin-large);
  line-height: var(--heading-line-height);
  font-weight: 600;
}

h4,
h5,
h6,
.heading-small {
  font-family: $font-family-cocon-regular;
  font-size: var(--heading-font-size-small);
  margin-bottom: var(--heading-margin-small);
  line-height: var(--heading-line-height);
  font-weight: 400;
}

p {
  margin-bottom: var(--spacing-l);
}

p,
.text-standard {
  font-size: var(--text-font-size-standard);
}
small,
.text-small {
  font-size: var(--text-font-size-small);
}
.text-large {
  font-size: var(--text-font-size-large);
}
.text-xlarge {
  font-size: var(--text-font-size-xlarge);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.semibold {
  font-weight: var(--font-weight-semibold);
}
