@use 'styles/variables' as *;

.serviceFlowContainer {
  padding: var(--spacing-4xl) var(--spacing-m);
  text-align: center;
  margin-bottom: var(--spacing-3xl);
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: var(--spacing-l);
  }
  p {
    font-size: var(--text-font-size-standard);
    color: var(--color-gray-standard);
    margin-bottom: var(--spacing-l);
  }

  a {
    font-size: var(--text-font-size-standard);
    font-weight: var(--font-weight-semibold);
    color: var(--color-blue-dark);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.title {
  font-size: var(--heading-font-size-small);
  font-weight: var(--font-weight-bold);
  color: var(--color-blue-dark);
  margin-bottom: var(--spacing-4xl);
  margin-top: var(--spacing-3xl);
}

.processRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: var(--spacing-l);
}
@media screen and (min-width: $breakpoint-l) {
  .processRow {
    width: 75rem;
  }
}
.processItem {
  background-color: var(--color-white);
  border-radius: var(--rounded-m);
  border: 1px solid #1c20222a;
  overflow: hidden;
  text-align: left;
  flex-basis: calc(33.333% - var(--spacing-l));
  max-width: 22.5rem;
  margin: 0 auto;
}

.textContainer {
  padding: 1.9375rem;
}

.itemTitle {
  font-size: var(--text-font-size-large);
  font-weight: var(--font-weight-semibold);
  color: var(--color-gray-darker);
  margin-bottom: var(--spacing-s);
}

@media screen and (max-width: $breakpoint-s) {
  .serviceFlowContainer {
    width: 100%;
    // button {
    //   width: 10px;
    //   height: 35px;
    //   border-radius: 50%;
    //   margin: 0 5px;
    //   transition: background-color 0.3s ease;
    // }
    p {
      font-size: var(--text-font-size-small);
    }

    a {
      font-size: var(--text-font-size-small);
    }
  }

  // Chrome
  .mobileSlider::-webkit-scrollbar {
    display: none;
  }

  .mobileSlider {
    scrollbar-width: none; // Firefox
    -ms-overflow-style: none; // Edge
    overflow: hidden;

    .processItem {
      width: 100%;
    }
    :global(.glider-track) {
      margin-bottom: 30px;
    }
  }

  .title {
    font-size: 24px;
  }

  .textContainer {
    padding: var(--spacing-l);
  }
}
