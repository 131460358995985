@use 'styles/variables' as *;

.header {
  margin-bottom: var(--spacing-l);
  @media screen and (max-width: $breakpoint-s) {
    margin-bottom: var(--spacing-s);
  }
  p {
    color: var(--color-white)
  }
}
.maingrid {
  @media screen and (min-width: $breakpoint-s) {
    margin-top: -4rem;
  }
}
.hidemobile {
  @media screen and (max-width: $breakpoint-s) {
    display: none !important;
  }
}
.hidexsmall {
  @media screen and (max-width: $breakpoint-xs) {
    display: none !important;
  }
}

.showxsmall {
  width: 100%;
  @media screen and (min-width: $breakpoint-xs) {
    display: none !important;
  }
}

.hidedesktop {
  @media screen and (min-width: $breakpoint-s) {
    display: none !important;
  }
}
.row {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-l);
  flex-wrap: nowrap;
  width: 100%;
  @media screen and (max-width: $breakpoint-xs) {
    flex-wrap: wrap;
  }
}

.column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}
