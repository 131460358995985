.orderForm {
  display: flex;
  flex-direction: column;

  input,
  textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #007bff;
      outline: none;
    }
  }

  textarea {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    resize: none;
    box-sizing: border-box;
    border-radius: 5px;
    transition: border-color 0.3s ease;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 2px;
  margin-bottom: 1em;
}

.submitButton {
  width: 33%;
}
