@use 'styles/variables' as *;

.informationalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l);
  height: auto;
}

@media screen and (min-width: $breakpoint-m) {
  #Customer {
    flex-direction: row-reverse;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-3xl);
  max-width: 75rem;
  width: 100%;

  @media screen and (max-width: $breakpoint-m) {
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--spacing-xl);
  }
}

.column {
  flex: 1;
  padding: 0 var(--spacing-m);

  @media screen and (max-width: $breakpoint-m) {
    padding: var(--spacing-m) 0;
  }
}

.imageContainer {
  display: flex;
  justify-content: start;
  align-items: center;

  @media screen and (max-width: $breakpoint-m) {
    justify-content: center;
  }
}

.image {
  width: 100%;
  max-width: 32rem;
  height: auto;
  object-fit: cover;
  border-radius: var(--rounded-s);

  @media screen and (max-width: $breakpoint-m) {
    max-width: 100%;
    margin-bottom: var(--spacing-l);
  }
}

.textContainer {
  max-width: 31.25rem;
  text-align: left;

  @media screen and (max-width: $breakpoint-m) {
    max-width: 100%;
    padding: 0 var(--spacing-s);
  }
}

.tag {
  background-color: #e7f0f5;
  color: #066696;
}

.tagBestallare {
  background-color: #fdefed;
  color: #e85d46;
}

.tag,
.tagBestallare {
  display: inline-block;
  font-size: var(--text-font-size-small);
  font-weight: var(--font-weight-semibold);
  padding: var(--spacing-xs) var(--spacing-s);
  border-radius: var(--rounded-xs);
  margin-bottom: var(--spacing-l);

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--text-font-size-small);
    margin-bottom: var(--spacing-m);
  }
}

.title {
  font-size: var(--heading-font-size-standard);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-darker);
  margin-bottom: var(--spacing-2xl);
  font-family: 'Nunito', sans-serif;

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--heading-font-size-medium);
  }

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--heading-font-size-small);
  }
}

.description {
  font-size: var(--text-font-size-standard);
  color: var(--color-gray-standard);
  line-height: 1.6;
  margin-bottom: var(--spacing-2xl);

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--text-font-size-small);
    margin-bottom: var(--spacing-l);
  }

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--text-font-size-small);
  }
}

.faqButton {
  margin-top: var(--spacing-l);

  @media screen and (max-width: $breakpoint-m) {
    width: 100%;
    padding: var(--spacing-s);
  }

  @media screen and (max-width: $breakpoint-xs) {
    margin-top: var(--spacing-m);
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .informationalContainer {
    padding: var(--spacing-m);
  }

  .row {
    max-width: 100%;
  }

  .textContainer {
    padding: var(--spacing-s);
  }

  .imageContainer {
    padding: var(--spacing-s);
  }
}
