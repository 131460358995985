.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

.image {
  width: 100%;
  max-width: 100%;
}

.buttonrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
