@use 'styles/variables' as *;

a.bars {
  color: var(--color-gray-darkest);
  &:hover {
    text-decoration: none;
  }
}
.bars {
  box-sizing: border-box;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--spacing-l);
  height: 10rem;
  overflow: hidden;
  padding: var(--spacing-l) var(--spacing-xl);
  cursor: pointer;
  p {
    margin-bottom: var(--spacing-s);
  }
  &.requestBar {
    border: var(--border-standard) var(--color-red-standard);
  }
}
.imgBox {
  width: 9rem;
  height: 8rem;
  display: flex;

  justify-content: center;
  align-items: center;
  margin-left: calc(var(--spacing-m) * -1);
}

.adimage {
  position: absolute;
  left: 0;
  top: 0;
  width: 10rem;
  height: 10rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.serviceItem {
  flex-grow: 1;
}

.headerinfobigscreen {
  display: flex;
  justify-content: space-between;
}
.headerinfosmallscreen {
  display: none;
}

.left {
  display: flex;
  gap: var(--gap);
}
.place,
.date {
  font-weight: var(--font-weight-normal);
  color: var(--color-gray-dark);
}

.centercontent {
  font-size: var(--text-font-size-large);
  height: 4rem;
  margin-bottom: 0rem;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}

.bottominfo {
  display: flex;
  justify-content: space-between;
}
.categories {
  display: flex;
  gap: var(--gap);
}

.priceText,
.priceValueAndUnit {
  color: var(--color-gray-darker);
  font-size: var(--text-font-size-Xlarge);
}

.priceText {
  font-weight: var(--font-weight-normal);
}
@media screen and (max-width: $breakpoint-s) {
  .bars {
    height: 8rem;
  }
  .categories {
    display: none !important;
  }
  .imgBox {
    width: 4rem;
    height: 4rem;

    justify-content: center;
    align-items: center;
  }

  .adimage {
    position: absolute;
    left: 1rem;
    top: 1rem;
    width: 4rem;
    height: 4rem;
    border-radius: var(--rounded);
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .headerinfobigscreen {
    display: none;
  }
  .headerinfosmallscreen {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
      font-size: var(--text-font-size-small) !important;
    }
  }

  .date {
    display: none;
  }

  .centercontent {
    order: -1;
    font-size: var(--text-font-size-standard);
    margin-bottom: -1rem !important;
  }

  .bottominfo {
    margin-top: var(--spacing-xl);
  }

  .imgBox {
    margin-bottom: auto;

    div {
      width: 4rem;
      height: 4rem;
    }
  }

  .priceText,
  .priceValueAndUnit {
    font-size: var(--text-font-size-small) !important;
  }
  .priceValueAndUnit {
    font-weight: var(--font-weight-bold);
  }

  .serviceLeft {
    margin-left: -5rem;
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .bars {
    border: 0;
    border-top: var(--border-thin) var(--color-gray-standard);
    border-bottom: 0;
    margin-bottom: 0;
    border-left: var(--border-thick-weight) solid transparent;

    &.requestBar {
      border-top: var(--border-thin) var(--color-gray-standard);
      border-left: var(--border-thick-weight) solid var(--color-red-standard);
      border-bottom: none;
      border-right: none;
    }

    &:nth-last-child(1) {
      margin-bottom: var(--spacing-l);
      border-bottom: var(--border-thin) var(--color-gray-standard);
    }
  }

  .serviceLeft {
    margin-left: -5.5rem;
  }
}
