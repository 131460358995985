@use 'styles/variables' as *;

.title {
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  padding: 46px 0 16px 0;
  border-bottom: 3px solid var(--color-blue-dark);
  font-family: $font-family-cocon-bold;
}

.box {
  justify-content: space-between;
  align-items: center;
  height: 200px;
  cursor: pointer;
  border: var(--border-standard) var(--color-white);

  &.request.checked {
    border-color: var(--color-red-standard);
  }
  &.service.checked {
    border-color: var(--color-blue-standard);
  }

  .img {
    max-width: 200px;
    max-height: 200px;
    @media screen and (max-width: 1380px) and (min-width: 1024px) {
      display: none;
    }
    @media screen and (max-width: $breakpoint-xs) {
      display: none;
    }
  }

  div {
    .text {
      font-weight: 600;
      font-size: 20px;
      max-width: 281px;
      overflow: hidden;
      white-space: normal;
    }
  }

  .circle,
  .blueCircle {
    height: 64px;
    width: 64px;
    border: 1px solid #e85d46;
    background-color: #f7f7f7;
    border-radius: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .blueCircle {
    border: 1px solid var(--color-blue-dark);
  }
}

@media screen and (max-width: 1381px) {
  .box {
    height: fit-content;
  }
  .svipperBox {
    height: 156px;
  }
}

@media screen and (max-width: $breakpoint-m) {
  .text {
    max-width: none !important;
  }
  .box {
    height: 140px;
  }
  .svipperBox {
    height: 140px;
  }
}

@media screen and (max-width: $breakpoint-s) {
  .img {
    margin-right: 1.5rem;
    width: 144px;
    height: 144px;
  }
  .box {
    height: fit-content;
  }
  .svipperBox {
    height: fit-content;
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .heading {
    font-size: 35px !important;
  }
  .title {
    padding-left: 1.5rem !important;
  }

  .text {
    margin-top: 1rem !important;
    font-size: 1rem !important;
  }
}
