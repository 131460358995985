@use '../../../styles/variables' as *;

.radioButtons {
  margin-bottom: 2rem;
  input {
    margin-right: 0.5rem;
  }
  label {
    color: var(--svippo-svart-ljus, #373737);
    font-size: var(--label-input-large-font-size);
    font-weight: 600;
  }
  cursor: pointer;
}

.radioButtonSpace {
  margin-bottom: 1rem;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  span {
    margin-right: 8px;
  }

  a:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: $breakpoint-s) {
  .modalContainer {
    height: 100%;
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .modalContainer {
    margin: 0;
    border-radius: 0;
  }
}
