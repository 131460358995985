@use 'styles/variables' as *;
a:hover {
  text-decoration: none !important;
}
.heading {
  font-size: 48px;
  font-weight: 700;
  color: var(--color-blue-dark);
  font-family: $font-family-cocon-bold;
}

.text {
  font-weight: 600;
  font-size: 20px;
  max-width: 281px;
  overflow: hidden;
  white-space: normal;
  margin-bottom: var(--spacing-l);
}

.img {
  width: 200px;
  height: 200px;

  @media screen and (max-width: 1380px) and (min-width: 1024px) {
    display: none;
  }
  @media screen and (max-width: $breakpoint-xs) {
    display: none;
  }
}

.button:hover {
  cursor: pointer;
}
.button {
  border-radius: 20px;
  border: none;
  padding: 0 24px;
  background: linear-gradient(255.22deg, #52b7f0 15.18%, #007ff5 62.77%);
  width: 129px;
  height: 40px;
  font-size: 16px;
  margin-top: 16px;
  color: #fff;
}

@media screen and (max-width: $breakpoint-m) {
  .text {
    max-width: none !important;
  }
}
@media screen and (max-width: $breakpoint-s) {
  .img {
    width: 144px;
    height: 144px;
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .heading {
    font-size: 35px;
  }
  .text {
    margin-top: 1rem !important;
    font-size: 1rem !important;
  }
}
