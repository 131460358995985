@use '../../../styles/variables' as *;

.profileImgButton {
  position: absolute;
  bottom: 0;
  right: 0;
  flex-shrink: 0;
}

.profileImgBox {
  width: var(--icon-size-XXlarge);
  height: var(--icon-size-XXlarge);
  position: relative;

  &:hover {
    cursor: pointer;
  }

  .profileImg {
    width: var(--icon-size-XXlarge);
    height: var(--icon-size-XXlarge);
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
  }

  .elipse {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--icon-widht-large);
    height: var(--icon-height-large);
    flex-shrink: 0;
    background: var(--color-blue-dark);
    border-radius: var(--rounded-full);
  }
  .cameraOverlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--color-blue-dark);
    cursor: pointer;

    .cameraOverlayImg {
      width: 64px;
      height: 64px;
      flex-shrink: 0;
    }
  }
}

.textBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-large);
  width: 100%;
}
