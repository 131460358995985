@use 'styles/variables' as *;

@import 'styles/buttons.scss';
@import 'styles/links.scss';
@import 'styles/typography.scss';
@import 'styles/colors.scss';
@import 'styles/forms.scss';

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
