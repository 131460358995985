@use '../../styles/variables' as *;

.searchbutton {
  @media screen and (min-width: $breakpoint-xs) {
    display: none !important;
  }
}

.hideonmedium {
  @media screen and (max-width: $breakpoint-m) {
    display: none !important;
  }
}
.hideonmediumup {
  @media screen and (min-width: $breakpoint-m) {
    display: none !important;
  }
}
.hideonsmall {
  @media screen and (max-width: $breakpoint-s) {
    display: none !important;
  }
}
.hideonsmallup {
  @media screen and (min-width: $breakpoint-s) {
    display: none !important;
  }
}
.hideonxsmall {
  @media screen and (max-width: $breakpoint-xs) {
    display: none !important;
  }
}

.container {
  display: flex;
  box-sizing: border-box;
  padding: var(--spacing-vertical-standard) var(--spacing-side-margin);
  margin-bottom: var(--spacing-l);
  z-index: 10;

  .bar {
    display: flex;
    box-sizing: border-box;
    width: var(--header-width);
    padding-left: var(--header-padding-left);
    padding-right: var(--header-padding-right);
    flex-direction: row;
    align-items: center;
    background-color: var(--color-white);
    border-radius: var(--rounded-full);
    height: var(--header-height);
    justify-content: space-between;
    box-shadow: var(--header-box-shadow);
    gap: var(--spacing-l);
    button,
    a {
      flex-shrink: 0;
    }
    .leftColumn {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-xl);
    }

    .rightColumn {
      display: flex;
      width: var(--header-width);
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      gap: var(--spacing-l);
    }

    .logo {
      width: auto;
      height: var(--logo-height);
      cursor: pointer;
    }

    nav {
      display: flex;
      flex-direction: row;

      a:not(:last-child) {
        margin-right: var(--header-margin-right);
      }
    }

    .login {
      display: flex;
      align-items: center;
      color: var(--color-blue-dark);
      font-weight: var(--font-weight-bold);
      &:hover {
        opacity: 0.75;
        cursor: pointer;
      }

      img {
        width: var(--icon-size-small);
        height: var(--icon-size-small);
      }
    }

    .searchBarContainer {
      margin: auto;
      width: 100%;
      max-width: 40rem;
      @media screen and (max-width: $breakpoint-xs) {
        display: none;
      }
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    .bar {
      border-radius: 0;
    }
  }

  @media screen and (max-width: $breakpoint-s) {
    .bar {
      .navigation {
        display: none;
      }
    }
  }
}
