$breakpoint-l: 1499px;
$breakpoint-m: 1279px;
$breakpoint-s: 1023px;
$breakpoint-xs: 639px;

$font-family-cocon-regular: 'cocon-regular';
$font-family-cocon-bold: 'cocon-bold';

$grid-name-main: 'main-grid';

$color-blue-dark: #066696;
$color-blue-standard: #007ff5;
$color-blue-light: #52b7f0;
$color-red-standard: #e85d46;
$color-red-light: #ed8f7f;
$color-green-standard: #21cc1e;

$color-gray-darker: #373737;
$color-gray-dark: #5e5e5e;
$color-gray-standard: #767676;
$color-gray-light: #bbbbbb;
$color-gray-lighter: #f0f0f0;
$color-gray-lightest: #f7f7f7;
$color-white: #ffffff;

$color-blue-marin: #066696;
$color-blue-marin-25: rgba($color-blue-marin, 0.25);

$breakpoints: (
  l: $breakpoint-l,
  m: $breakpoint-m,
  s: $breakpoint-s,
  xs: $breakpoint-xs,
);

$colors: (
  blue-dark: $color-blue-dark,
  blue: $color-blue-standard,
  blue-light: $color-blue-light,
  red: $color-red-standard,
  red-light: $color-red-light,
  green: $color-green-standard,

  gray-darker: $color-gray-darker,
  gray-dark: $color-gray-dark,
  gray: $color-gray-standard,
  gray-light: $color-gray-light,
  gray-lighter: $color-gray-lighter,
  gray-lightest: $color-gray-lightest,
  white: $color-white,
  blue-marin: $color-blue-marin,
  blue-marin-25: $color-blue-marin-25,
);
