@use 'styles/variables' as *;

.modal {
  background: $color-gray-lightest;
  z-index: 10;
  padding: var(--spacing-l);
  margin-top: var(--spacing-s);
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  padding-bottom: var(--spacing-l);
  box-shadow: var(--shadow-standard);
  @media screen and (min-width: $breakpoint-s) {
    display: none;
  }
}

.form {
  display: flex;
  gap: var(--spacing-l);
  flex-direction: column;
  @media screen and (min-width: $breakpoint-xs) {
    display: none;
  }
}

.wrapper {
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  max-width: 100%;
  padding: 0;
  margin-top: calc(var(--spacing-l) * -1);
}

.menu {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-l);
  justify-content: center;
}
