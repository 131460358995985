@use 'styles/variables' as *;

.heading {
  @media screen and (max-width: $breakpoint-xs) {
    display: block;
    width: 100%;
    background-color: var(--color-white);
    margin-bottom: 0;
    margin-left: calc(var(--spacing-2xl) * -1);
    margin-right: calc(var(--spacing-2xl) * -1);
    padding: var(--spacing-xl) var(--spacing-2xl);
  }
}
.bottommargin {
  margin-bottom: var(--spacing-xl);
}
