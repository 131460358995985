@keyframes fly {
  0% {
    transform: scale(0.75) rotate(35deg);
    left: -75%;
    opacity: 0;
    top: 75%;
  }
  33% {
    transform: scale(1) rotate(0);
    opacity: 1;
    left: 0;
    top: 0;
  }

  66% {
    transform: scale(0.75) rotate(-10deg);
    opacity: 0;
    left: 75%;
    top: -75%;
  }
  100% {
    transform: scale(0.75) rotate(-10deg);
    opacity: 0;
    left: -75%;
    top: -75%;
  }
}

.wrapper {
  width: 100%;
  min-height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinnerBox {
  position: relative;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  &.large {
    width: 4rem;
    height: 4rem;
  }
}
.spinner {
  position: absolute;
  width: 2rem;
  height: 2rem;
  animation: 1s infinite ease-in-out fly;
  align-items: center;
  &.large {
    width: 4rem;
    height: 4rem;
  }
}
