@use 'styles/variables' as *;

.dropdown {
  // display: none;
  visibility: hidden;
  position: absolute;
  background: $color-gray-lightest;
  right: 0;
  z-index: 10;
  width: 20rem;
  border-radius: var(--rounded-l);
  border: var(--border);
  padding: 0.5rem;
  box-shadow: var(--shadow-standard);
  margin-top: 0.5rem;
  transition: opacity 0.2s ease-in;
  @media screen and (min-width: $breakpoint-s) {
    opacity: 0;
  }

  a {
    display: flex;
    padding: var(--spacing-s) var(--spacing-l);
    text-decoration: none;
    color: $color-gray-darker;
    border-radius: var(--rounded-m);
    font-size: 1rem;
    font-weight: 600;

    &:hover {
      background: $color-blue-dark;
      color: white;
      text-decoration: none;
    }
  }
  a:last-child {
    margin-bottom: 0rem;
  }

  hr {
    display: flex;
    width: 100%;
    height: 1px;
    padding: 0;
    border: 0;
    border-top: var(--border-hairline);
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-s);
  }
}

.mobilemenu {
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  max-width: 100%;
  padding: 0 var(--spacing-xl);
  .dropdown {
    visibility: visible;
    position: relative;

    width: 100%;
    box-sizing: border-box;
  }
  @media screen and (min-width: $breakpoint-s) {
    display: none !important;
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding: 0;
    height: 100%;
    margin-top: calc(var(--spacing-l) * -1);
    .dropdown {
      height: 100%;
      border: 0;
      border-radius: 0;
    }
  }
}

.visible {
  @media screen and (min-width: $breakpoint-s) {
    display: block;
    visibility: visible;
    opacity: 1;
  }
}

.profileWrapper {
  position: relative;
  height: var(--button-height-standard);
  width: var(--button-height-standard);
}
.iconUser {
  height: inherit;
  width: inherit;
  object-fit: cover;
  background-color: var(--color-white);
}
