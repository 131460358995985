@use '../../../styles/variables' as *;

.radiocard {
  cursor: pointer;
  border: var(--border-standard-weight) solid var(--color-white);
  @media screen and (max-width: $breakpoint-xs) {
    border: 0;
  }
}

.checked {
  border: var(--border);
  @media screen and (max-width: $breakpoint-xs) {
    border: 0;
    border-left: var(--border-thick) var(--color-blue-dark);
    padding-left: calc(var(--cards-modals-padding) - var(--border-thick-weight));
  }
}

.disabled,
.disabled.checked {
  cursor: default;
  color: var(--color-gray-light);
  border: var(--border-standard-weight) solid var(--color-white);
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: var(--cards-modals-gap);
}

.radiobutton {
  flex-shrink: 0;
  width: var(--button-height-large) !important;
  height: var(--button-height-large);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  opacity: 1 !important;
}

.contentcontainer {
  display: flex;
  width: 100%;
  flex-direction: column;
}
