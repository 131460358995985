@use '../../../styles/variables' as *;

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    span {
        margin-right: 8px;
    }

    a:hover {
		cursor: pointer;
	}
}