@use './variables' as *;

fieldset,
form {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: var(--text-font-size-standard);
  display: flex;
  flex-direction: column;

  &.row {
    flex-direction: row;
  }
  &.large {
    font-size: var(--text-font-size-large);
  }
}

//RADIO
$radio-size-standard: 1.25rem;
$radio-size-large: 1.5rem;
$radio-label-size-standard: 1.5rem;
$radio-label-size-large: 2rem;

input[type='radio'] {
  opacity: 0;
  width: 0;
  & + label {
    position: relative;
    margin-bottom: var(--spacing-s);
    margin-top: var(--spacing-xs);
    display: inline-flex;
    align-items: center;
    height: $radio-label-size-standard;
    font-weight: var(--font-weight-semibold);
    font-size: var(--text-font-size-standard);
    &:before {
      box-sizing: border-box;
      opacity: 1;
      content: ' ';
      display: inline-block;
      width: $radio-size-standard;
      height: $radio-size-standard;
      border-radius: var(--rounded-full);
      margin-right: var(--spacing-s);
      border: var(--border-standard) #{$color-gray-standard};
    }
  }
  &:checked + label {
    &:before {
      border: var(--border-standard) #{$color-green-standard};
      content: ' ';
      background: radial-gradient(
        circle at center,
        var(--color-gray-dark) 0,
        var(--color-gray-dark) 38%,
        rgba(255, 255, 255, 0) 40%
      );
    }
  }
  &:focus-visible + label:before {
    border: var(--border-standard) #{$color-blue-standard};
  }
  &:invalid + label:before {
    border: var(--border-standard) #{$color-red-standard};
  }
}

fieldset.large input[type='radio'],
form.large input[type='radio'],
input[type='radio'].large {
  & + label {
    font-size: var(--text-font-size-large);
    height: $radio-label-size-large;
    margin-bottom: var(--spacing-l);
    margin-top: var(--spacing-m);
    &:before {
      width: $radio-size-large;
      height: $radio-size-large;
    }
  }
}

//CHECKBOX
$checkbox-size-standard: 1.5rem;
$checkbox-size-large: 2rem;

input[type='checkbox'] {
  opacity: 0;
  width: 0;
  & + label {
    margin-bottom: var(--spacing-s);
    margin-top: var(--spacing-xs);
    display: inline-flex;
    height: $checkbox-size-standard;
    align-items: center;
    font-weight: var(--font-weight-semibold);
    font-size: var(--text-font-size-standard);
    &:before {
      box-sizing: border-box;
      opacity: 1;
      content: ' ';
      display: inline-block;
      width: $checkbox-size-standard;
      height: $checkbox-size-standard;
      border-radius: var(--rounded-xs);
      margin-right: var(--spacing-s);
      border: var(--border-standard) #{$color-gray-standard};
    }
  }

  &:checked + label:before {
    border: var(--border-standard) #{$color-green-standard};
    background: url(../assets/icons/checkbox.svg) center, $color-green-standard;
    content: ' ';
    background-size: cover;
  }
  &:focus-visible + label:before {
    border: var(--border-standard) #{$color-blue-standard};
  }
  &:invalid + label:before {
    border: var(--border-standard) #{$color-red-standard};
  }
}

fieldset.large input[type='checkbox'],
form.large input[type='checkbox'],
input[type='checkbox'].large {
  & + label {
    font-size: var(--text-font-size-large);
    height: $checkbox-size-large;
    margin-bottom: var(--spacing-l);
    margin-top: var(--spacing-m);
    &:before {
      width: $checkbox-size-large;
      height: $checkbox-size-large;
      border-radius: var(--rounded-s);
    }
  }
}

label,
legend {
  vertical-align: baseline;
  margin-bottom: var(--spacing-xs);
  font-weight: var(--font-weight-semibold);
  font-size: var(--text-font-size-standard);
}

label.lage,
legend.large {
  margin-bottom: var(--spacing-s);
  font-size: var(--text-font-size-large);
}
