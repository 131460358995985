@use 'styles/variables' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.menubar {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: space-between;
}

.hidemobile {
  @media screen and (max-width: $breakpoint-s) {
    display: none;
  }
}

.pagegroup {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-l);
}

.separator {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mobilewrapper {
  display: none;
  @media screen and (max-width: $breakpoint-s) {
    display: flex;
    justify-content: center;
  }
}
.loadbutton {
  max-width: 18rem;
  width: 18rem;
  min-width: fit-content;
}
