@use '../../styles/variables' as *;
:global {
  .card {
    display: flex;
    background-color: var(--color-white);
    padding: var(--cards-modals-padding);
    row-gap: var(--cards-modals-gap);
    column-gap: var(--cards-modals-gap);
    border-radius: var(--cards-modals-corner-radius);
    box-shadow: var(--shadow-standard);
    flex-direction: column;
    &.row {
      flex-direction: row;
    }
    @media screen and (max-width: $breakpoint-xs) {
      border-radius: 0;
    }
  }

  .gridContainer > .card, .gridColumn > .card {
    @media screen and (max-width: $breakpoint-xs) {
      margin-left: calc(var(--spacing-side-margin) * -1);
      margin-right: calc(var(--spacing-side-margin) * -1);
    }
  }
}
