@use 'styles/variables' as *;

.profileImgBox {
  width: var(--icon-size-XXlarge);
  height: var(--icon-size-XXlarge);
  position: relative;
  margin: auto;
  cursor: pointer;
}
.profileImg {
  width: var(--icon-size-XXlarge);
  height: var(--icon-size-XXlarge);
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.profileImgButton {
  position: absolute;
  bottom: 0;
  right: 0;
  flex-shrink: 0;
  cursor: pointer;
}
