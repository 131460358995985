@use 'styles/variables' as *;

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--header-width);
  z-index: 1;
}

.banner {
  padding-top: var(--homepage-intro-vertical-spacing-top);
  padding-bottom: var(--homepage-intro-vertical-spacing-bottom);
  margin-bottom: var(--homepage-intro-vertical-margin-bottom);
  background-position: var(--header-width);
}

.box {
  justify-content: space-between;
  align-items: center;

  img {
    display: block;
    max-width: 30%;
    width: auto;
    height: auto;
    flex-shrink: 0;
    margin-top: -1.5rem;
  }

  p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.buttonwrapper {
  display: flex;
  gap: var(--spacing-l);
  justify-content: flex-end;
  @media screen and (max-width: $breakpoint-xs) {
    margin-top: var(--spacing-xl);
    flex-direction: column;
  }
}

@media screen and (max-width: $breakpoint-m) {
}

@media screen and (max-width: $breakpoint-s) {
}

@media screen and (max-width: $breakpoint-xs) {
  .box {
    img {
      display: none;
    }
  }

  .btnBox {
    flex-direction: column;
  }
}
