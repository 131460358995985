@use 'styles/variables' as *;

.stepper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  li {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-s);
    &.clickable {
      cursor: pointer;
    }
    &:first-child {
      hr:first-child {
        border-color: transparent;
      }
    }
    &:last-child {
      hr:last-child {
        border-color: transparent;
      }
    }
    .topWrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      gap: var(--spacing-s);
    }
    .bullet {
      flex-shrink: 0;
      text-decoration: none !important;
      font-size: var(--text-font-size-small);
      background-color: var(--color-white);
      border: var(--border-standard) var(--color-blue-dark);
      color: var(--color-blue-dark);
      height: 1.5rem;
      width: 1.5rem;
      border-radius: var(--rounded-full);
      display: flex;
      align-items: center;
      justify-content: center;
      &.active,
      &.done {
        color: var(--color-white);
        background-color: var(--color-blue-dark);
        border: var(--border-standard) var(--color-blue-dark);
      }
      &:not(.disabled):hover {
        font-weight: var(--font-weight-bold);
      }
      &.disabled {
        color: var(--color-white);
        background-color: var(--color-gray-light);
        border: var(--border-standard) var(--color-gray-light);
      }
    }
    &:hover {
      .bullet:not(.disabled) {
        font-weight: var(--font-weight-bold);
      }
      &.clickable {
        .label {
          text-decoration: underline;
        }
      }
    }
  }
  hr {
    height: fit-content;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    border-color: var(--color-blue-marin-25);
  }
  .divider {
    margin-top: 0.75rem;
    max-width: 15rem;
    @media screen and (min-width: $breakpoint-xs) {
      margin-top: calc(0.75rem + 2px);
    }
  }
}
