@use 'styles/variables' as *;

.wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  .search {
    position: relative;
    box-sizing: border-box;
    margin-left: auto;
    width: 100%;
    flex-shrink: 1;
    font-size: var(--text-font-size-standard);
    height: 2.5rem;
    border: var(--border-standard) var(--color-blue-dark);
    border-radius: var(--rounded-full);
    padding-right: var(--spacing-m);
    padding-left: calc(var(--spacing-m) + var(--icon-size-medium) + var(--spacing-s));
    background-color: var(--color-white);
    &:focus-visible {
      outline-color: var(--color-blue-standard);
    }
    &.light {
      border: var(--border-standard) var(--color-blue-marin-25);
    }
  }
  &:after {
    content: ' ';
    background: url('/assets/icons/icon-search.svg');
    background-size: var(--icon-size-medium) var(--icon-size-medium);
    background-position: center center;
    background-repeat: no-repeat;
    width: var(--icon-size-medium);
    height: 2.5rem;
    display: block;
    position: absolute;
    left: var(--spacing-m);
    top: 0;
  }
  &.large {
    .search {
      height: 4rem;
      gap: var(--spacing-m);
      border: none;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.12); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */

      padding-top: var(--spacing-m);
      padding-bottom: var(--spacing-m);
      padding-right: var(--spacing-l);
      padding-left: calc(var(--spacing-l) + var(--icon-size-large) + var(--spacing-m));
      font-size: var(--text-font-size-large);
      background-size: var(--icon-size-large) var(--icon-size-large);
      background-position: var(--spacing-l) center;
    }
    &:after {
      background-size: var(--icon-size-medium) var(--icon-size-medium);
      background-position: center center;
      width: var(--icon-size-large);
      height: 4rem;
      left: var(--spacing-l);
      top: 0;
    }
  }
  @media (max-width: $breakpoint-xs) {
    &.large .search {
      height: 3rem;
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);
      padding-left: calc(var(--spacing-xl) + var(--icon-size-large) + var(--spacing-m));
      font-size: var(--text-font-size-medium);
      background-size: var(--icon-size-small) var(--icon-size-small);
      background-position: var(--spacing-s) center;
    }
    &.large:after {
      width: var(--icon-size-small);
      height: 3rem;
      left: var(--spacing-xl);
    }
  }
}
