@use './variables' as *;

@mixin button-light($color: var(--color-gray-darker)) {
  border: 0;
  color: var(--color-gray-darker);
  background-color: transparent;
  &:disabled {
    color: var(--color-gray-light);
  }
  &:hover:not(:disabled) {
    @include button-solid($color, false);
  }
}

@mixin button-outlined($color: var(--color-gray-darker), $fallbackColor: $color) {
  border: var(--border-standard);
  border-color: $fallbackColor;
  color: $fallbackColor;
  background: var(--color-gray-lightest);
  &:disabled {
    border-color: var(--color-gray-light);
    color: var(--color-gray-light);
    background: var(--color-gray-lightest);
  }
  &:hover:not(:disabled) {
    @include button-solid($color, false);
    border: var(--border-standard);
    border-color: $fallbackColor;
  }
}
@mixin button-menu($color: var(--color-gray-darker)) {
  border: 0;
  color: $color;
  background: var(--color-gray-lighter);
  &:disabled {
    color: var(--color-gray-light);
    background: var(--color-gray-lightest);
  }
  &:hover:not(:disabled) {
    @include button-solid($color, false);
  }
}

@mixin button-inverted($color: var(--color-gray-darker), $fallbackColor: $color) {
  border: 0;
  background: var(--color-white);
  color: $fallbackColor;
  &:disabled {
    color: var(--color-gray-light);
  }
  &:hover:not(:disabled) {
    @include button-solid($color, false);
  }
}

@mixin button-solid($color, $hover: true) {
  border: none;
  color: var(--color-white);
  background: $color;
  &:disabled {
    background: var(--color-gray-light);
  }
  &:hover:not(:disabled) {
    @if $hover {
      box-shadow: var(--shadow-small);
      &:after {
        content: ' ';
        border-radius: inherit;
        width: inherit;
        height: inherit;
        background-color: var(--color-white);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.2;
      }
    } @else {
      opacity: 1;
      box-shadow: none;
    }
  }
}

button,
.button {
  display: inline-flex;
  font-weight: var(--font-weight-normal);
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  &:hover {
    text-decoration: none !important;
  }
  cursor: pointer;
  gap: var(--spacing-s);
  &:disabled {
    cursor: default;
  }
  overflow: hidden;
  &:not(.link) {
    height: var(--button-height-standard);
    padding: 0 var(--button-padding-standard);
    color: var(--color-white);
    border-radius: calc(var(--button-height-standard) / 2);
    white-space: nowrap;
    font-family: $font-family-cocon-regular;
    font-size: var(--text-font-size-standard);
    justify-content: center;
    align-items: center;
    @include button-solid(var(--color-blue-standard));
  }

  svg,
  img {
    line-height: 0;
    vertical-align: middle;
    width: var(--icon-size-medium);
    height: var(--icon-size-medium);
    @media screen and (max-width: $breakpoint-s) {
      width: var(--icon-size-small);
      height: var(--icon-size-small);
    }
  }
  &.large {
    &:not(.link) {
      height: var(--button-height-large);
      border-radius: calc(var(--button-height-large) / 2);
      padding: 0 var(--button-padding-large);
      font-size: var(--text-font-size-large);
    }
    svg,
    img {
      width: var(--icon-size-large);
      height: var(--icon-size-large);
      @media screen and (max-width: $breakpoint-s) {
        width: var(--icon-size-medium);
        height: var(--icon-size-medium);
      }
    }
  }
  &.xlarge {
    &:not(.link) {
      height: var(--button-height-xlarge);
      border-radius: calc(var(--button-height-xlarge) / 2);
      padding: 0 var(--button-padding-xlarge);
      font-size: var(--text-font-size-xlarge);
    }
    svg,
    img {
      width: var(--icon-size-xlarge);
      height: var(--icon-size-xlarge);
      @media screen and (max-width: $breakpoint-s) {
        width: var(--icon-size-large);
        height: var(--icon-size-large);
      }
    }
  }
  &.bullet {
    width: var(--button-height-standard);
    max-width: var(--button-height-standard);
    padding: 0;
    &.large {
      width: var(--button-height-large);
      max-width: var(--button-height-large);
      padding: 0;
    }
    &.xlarge {
      width: var(--button-height-xlarge);
      max-width: var(--button-height-xlarge);
      padding: 0;
    }
  }

  &.primary {
    @include button-solid(var(--color-blue-gradient));
  }
  &.blue {
    @include button-solid(var(--color-blue-standard));
  }
  &.darkblue {
    @include button-solid(var(--color-blue-dark));
  }
  &.green,
  &.success {
    @include button-solid(var(--color-green-standard));
  }
  &.red {
    @include button-solid(var(--color-red-gradient));
  }
  &.error {
    @include button-solid(var(--color-red-standard));
  }

  &.outlined {
    &.primary {
      @include button-outlined(var(--color-blue-gradient), var(--color-blue-standard));
    }
    &.blue {
      @include button-outlined(var(--color-blue-standard));
    }
    &.darkblue {
      @include button-outlined(var(--color-blue-dark));
    }
    &.green,
    &.success {
      @include button-outlined(var(--color-green-standard));
    }
    &.red {
      @include button-outlined(var(--color-red-gradient), var(--color-red-standard));
    }
    &.error {
      @include button-outlined(var(--color-red-standard));
    }
    @include button-outlined(var(--color-gray-darker));
  }

  &.inverted {
    &.primary {
      @include button-inverted(var(--color-blue-gradient), var(--color-blue-standard));
    }
    &.blue {
      @include button-inverted(var(--color-blue-standard));
    }
    &.darkblue {
      @include button-inverted(var(--color-blue-dark));
    }
    &.green,
    &.success {
      @include button-inverted(var(--color-green-standard));
    }
    &.red {
      @include button-inverted(var(--color-red-gradient), var(--color-red-standard));
    }
    &.error {
      @include button-inverted(var(--color-red-standard));
    }
    @include button-inverted(var(--color-gray-darker));
  }

  &.light {
    &.primary {
      @include button-light(var(--color-blue-standard));
    }
    &.blue {
      @include button-light(var(--color-blue-standard));
    }
    &.darkblue {
      @include button-light(var(--color-blue-dark));
    }
    &.green,
    &.success {
      @include button-light(var(--color-green-standard));
    }
    &.red {
      @include button-light(var(--color-red-standard));
    }
    &.error {
      @include button-light(var(--color-red-standard));
    }
    @include button-light(var(--color-gray-darker));
  }

  &.menu {
    &.primary {
      @include button-menu(var(--color-blue-standard));
    }
    &.blue {
      @include button-menu(var(--color-blue-standard));
    }
    &.darkblue {
      @include button-menu(var(--color-blue-dark));
    }
    &.green,
    &.success {
      @include button-menu(var(--color-green-standard));
    }
    &.red {
      @include button-menu(var(--color-red-standard));
    }
    &.error {
      @include button-menu(var(--color-red-standard));
    }
    @include button-menu(var(--color-gray-darker));
  }

  &.iconLeft {
    padding-right: var(--button-padding-standard);
    padding-left: var(--icon-button-padding-standard);
    &.large {
      padding-right: var(--button-padding-large);
      padding-left: var(--icon-button-padding-large);
    }
    &.xlarge {
      padding-right: var(--button-padding-xlarge);
      padding-left: var(--icon-button-padding-xlarge);
    }
    &.bullet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.iconRight {
    padding-left: var(--button-padding-standard);
    padding-right: var(--icon-button-padding-standard);
    &.large {
      padding-left: var(--button-padding-large);
      padding-right: var(--icon-button-padding-large);
    }
    &.large {
      padding-left: var(--button-padding-xlarge);
      padding-right: var(--icon-button-padding-xlarge);
    }
    &.bullet {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.link {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    display: inline;
    font-size: inherit;
  }
  &.fullWidth {
    width: 100%;
  }
}
