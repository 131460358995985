.wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  svg {
    height: var(--icon-size-medium);
    width: var(--icon-size-medium);
  }
  &.large {
    label {
      font-size: var(--text-font-size-large);
    }
    svg {
      height: var(--icon-size-large);
      width: var(--icon-size-large);
    }
  }
}

.header {
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-weight: var(--font-weight-semibold);
  justify-content: space-between;
  padding-bottom: var(--spacing-s);
  border-bottom: var(--border-standard) var(--color-gray-light);
  cursor: pointer;
}

.content {
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-l);
}
