@use 'styles/variables' as *;

.header {
  padding-bottom: var(--spacing-3xl);
  margin-bottom: var(--spacing-3xl);
}
.pagestyle {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}

.bubbleRow {
  margin-top: -5vw;
}

.sellServices {
  color: var(--color-blue-dark);
  img {
    border: #fff solid 1em;
    border-radius: 360px;
  }
}

.buyServices {
  color: var(--color-red-standard);
  img {
    border: #fff solid 1em;
    border-radius: 360px;
  }
}

.carrousel {
  max-width: 100%;
  img {
    width: 100%;
  }
}

@media screen and (min-width: $breakpoint-m) {
  .bubbleRow {
    margin-top: -9rem;
  }

  .carrousel {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-m) {
  .bubbleRow {
    margin-top: -9rem;
  }

  .carrousel {
    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: $breakpoint-s) {
  .pagestyle {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 90%;
    margin: 0 auto;
  }
  .bubbleRow {
    margin-top: -5rem;
    img {
      width: 70%;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .header {
    margin-bottom: 15%;
  }

  .bubbleRow {
    margin-top: -9rem;
  }

  .carrousel {
    display: none;
  }
}
