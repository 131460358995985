@use '../../styles/variables' as *;

.container {
  container-type: inline-size;
  container-name: container;

  form {
    display: flex;
    flex-direction: column;
    color: var(--color-gray-darker);

    label {
      cursor: pointer;
    }

    .row {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      grid-column-gap: var(--spacing-gutter-size);
    }

    .field {
      margin-bottom: 14px;
    }

    .checkbox {
      display: flex;
      :hover {
        cursor: pointer;
      }

      input {
        margin-right: var(--input-checkbox-standard-label-spacing);
        margin-left: var(--input-checkbox-standard-label-spacing);
        transform: scale(2);
      }
    }

    button {
      margin-top: 32px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .container form {
    .row {
      grid-template-columns: repeat(1, minmax(150px, 1fr));
      grid-template-rows: repeat(2, 1fr);
    }
  }
}
