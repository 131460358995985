@use '../../styles/variables' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  &:hover {
    .editButton {
      visibility: visible;
    }

    .profileImgButton {
      visibility: visible;
      cursor: pointer;
    }
  }
}
.profileImgBox {
  width: var(--icon-size-XXlarge);
  height: var(--icon-size-XXlarge);
  position: relative;
}
.profileImg {
  width: var(--icon-size-XXlarge);
  height: var(--icon-size-XXlarge);
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

.profileImgButton {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  right: 0;
  flex-shrink: 0;
}
.cameraImg {
  width: var(--icon-width-small);
  height: var(--icon-height-small);
  flex-shrink: 0;
}

.centerBox {
  margin: auto;
  text-align: center;
  position: relative;
}

.textBox {
  text-align: center;
  h2 {
    position: relative;
    display: inline;
  }
}
.editButton {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: calc(var(--button-height-large) * -1);
}

.placeholderText {
  color: var(--color-gray-standard);
  font-style: italic;
}

ul.infolist {
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: column;
  gap: var(--spacing-l);
  justify-content: flex-start;
  li {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

#association {
  margin-top: 2rem;

  img {
    width: 71px;
    height: auto;
  }

  h6 {
    font-size: 20px;
  }

  img {
    height: auto;
  }
}
