@use './styles/variables' as *;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@700;800;900&display=swap');

@font-face {
  font-family: $font-family-cocon-regular;
  src: url('./assets/fonts/Cocon-Regular.otf');
}
@font-face {
  font-family: $font-family-cocon-bold;
  src: url('./assets/fonts/Cocon-Bold.otf');
}

* {
  margin: 0;
  font-family: inherit;
}

body {
  margin: 0;
  font-family: 'Nunito Sans', 'Roboto', Arial, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-gray-darker);
}
hr {
  border-left-color: var(--color-gray-light);
  border-bottom-color: var(--color-gray-light);
  border-top: none;
  border-right: none;
}

:root {
  font-size: 16px;

  --logo-height: 2.5rem;

  // Avstånd
  --spacing-xs: 0.25rem;
  --spacing-s: 0.5rem;
  --spacing-m: 0.75rem;
  --spacing-l: 1rem;
  --spacing-xl: 1.5rem;
  --spacing-2xl: 2rem;
  --spacing-3xl: 3rem;
  --spacing-side-margin: 3rem;
  --spacing-gutter-size: 2rem;
  --spacing-sidehead: 2rem;
  --spacing-vertical-standard: 1.5rem;
  --spacing-vertical-large-between-forms: 2rem;
  --spacing-vertical-large-between-sections: 3rem;
  --container-padding: 2rem;

  //Borders
  --border-thick-weight: 0.5rem;
  --border-standard-weight: 0.125rem;
  --border-thin-weight: 0.0625rem;

  --border-thick: var(--border-thick-weight) solid;
  --border-standard: var(--border-standard-weight) solid;
  --border-thin: var(--border-thin-weight) solid;
  --border: var(--border-standard) #{$color-blue-dark};
  --border-hairline: var(--border-thin) #{$color-blue-marin-25};

  //Border radius
  --rounded-full: 999999rem;
  --rounded-xs: 0.25rem;
  --rounded-s: 0.5rem;
  --rounded-m: 0.75rem;
  --rounded-l: 1rem;
  --rounded: var(--rounded-l);

  // Textstorlekar
  --text-font-size-small: 0.875rem;
  --text-font-size-standard: 1rem;
  --text-font-size-large: 1.25rem;
  --text-font-size-Xlarge: 1.5rem;
  --text-font-size-XXlarge: 2rem;

  --heading-font-size-large: 3rem;
  --heading-margin-large: 1rem;
  --heading-font-size-standard: 2rem;
  --heading-margin-standard: 1rem;
  --heading-font-size-small: 1.75rem;
  --heading-margin-small: 1rem;
  --heading-line-height: 100%;

  // Ikoner
  --icon-size-small: 1.25rem;
  --icon-size-medium: 1.5rem;
  --icon-size-large: 2rem;
  --icon-size-Xlarge: 2.5rem;
  --icon-size-XXlarge: 10rem;
  --icon-border: 0.2rem;
  --icon-closeBtn-width: 2rem;
  --icon-closeBtn-height: 2rem;

  // Knappar (CoconPro regular)
  --button-height-standard: 2.5rem;
  --button-padding-standard: 1.5rem;
  --button-height-large: 3rem;
  --button-padding-large: 1.75rem;
  --button-height-xlarge: 4rem;
  --button-padding-xlarge: 2.5rem;
  --icon-button-padding-standard: 1rem;
  --icon-button-padding-large: 1.25rem;
  --icon-button-padding-xlarge: 1.5rem;
  --icon-button-size-standard: var(--icon-size-medium);
  --icon-button-size-large: var(--icon-size-large);
  --icon-button-size-xlarge: var(--icon-size-xlarge);

  //Shadows
  --shadow-standard: 0 0.5rem 2rem 0px rgba(0, 0, 0, 0.1);
  --shadow-small: 0 0.125rem 0.125rem 0px rgba(0, 0, 0, 0.25);

  // Sidhuvud
  --header-width: 100%;
  --header-max-width: 20rem;
  --header-height: 5rem;
  --header-padding-left: 2rem;
  --header-padding-right: 2rem;
  --header-padding-top: 2rem;
  --header-padding-bottom: 1rem;
  --header-margin-top: 2rem;
  --header-margin-left: 1.5rem;
  --header-margin-right: 1.5rem;
  --header-margin-right-small: 1.5rem;
  --header-box-shadow: var(--shadow-standard);

  --search-height: 2.5rem;
  --search-padding-left: 0.75rem;
  --search-padding-right: 0.75rem;
  --search-height-large: 4rem;
  --search-padding-left-large: 1rem;
  --search-padding-right-large: 1rem;
  --search-border: 2px;

  // Footer
  --footer-width: 100%;
  --footer-max-width: 20rem;
  --footer-height: 5rem;
  --footer-padding-left: 10rem;
  --footer-padding-right: 10rem;
  --footer-padding-top: 2rem;
  --footer-padding-bottom: 1rem;
  --footer-margin-top: 2rem;
  --footer-margin-left: 3rem;
  --footer-margin-right: 3rem;
  --footer-margin-right-small: 1.5rem;
  --footer-box-shadow: var(--shadow-standard);

  // Startsida (ej inloggad)
  --homepage-intro-text-block-width: calc((100% / 12) * 5); // For 5 columns width
  --homepage-intro-vertical-spacing-top: 10rem;
  --homepage-intro-vertical-spacing-bottom: 10rem;
  --homepage-intro-vertical-margin-bottom: -6.5rem;
  --homepage-intro-vertical-spacing-header: 4.5rem;

  --homepage-post-cloud-block-width: calc((100% / 12) * 5);
  --homepage-post-cloud-block-left-margin: calc((100% / 12) * 7); // For 7 columns left margin

  --homepage-round-entrance-spacing-intro: 4rem;
  --homepage-round-entrance-text-area-width: calc((100% / 12) * 5);
  --homepage-round-entrance-text-area-left-margin: calc((100% / 12) * 1);
  --homepage-round-entrance-text-area-right-margin: calc((100% / 12) * 1);

  // Ruta overlay
  --overlay-width: calc((100% / 12) * 4);
  --overlay-width-left-margin: calc((100% / 12) * 2);
  --overlay-margin-top: 3rem;
  --overlay-padding: 3rem;
  --overlay-border-radius: 1rem;
  --overlay-close-button: var(--icon-size-large);

  // Formulär large
  --form-vertical-spacing: 2rem;
  --form-vertical-input-spacing: 1rem;
  --form-horizontal-input-spacing: 1rem;
  --form-vertical-radio-spacing: 0;

  --label-input-large-font-size: var(--text-font-size-large);
  --label-input-standard-font-size: var(--text-font-size-standard);

  /* Large Input */
  --input-height-large: 3rem;
  --input-text-size-large: var(--text-font-size-large);
  --input-large-padding-horisontal: 1rem;
  --input-large-padding-vertical: 0.75rem;
  --input-large-border-width: var(--border-standard);
  --input-large-border-radius: var(--rounded-m);
  --input-large-error-margin: 0.5rem;
  --input-large-error-icon-size: var(--icon-size-medium);
  --input-large-error-text-font-size: var(--text-font-size-standard);

  /* Standard Input */
  --input-height-standard: 2.5rem;
  --input-text-size-standard: var(--text-font-size-standard);
  --input-standard-padding-horisontal: 1rem;
  --input-standard-padding-vertical: 0.5rem;
  --input-standard-border-width: var(--border-standard);
  --input-standard-border-radius: var(--rounded-s);
  --input-border-radius-large: 0.75rem;
  --input-standard-error-margin: 0.25rem;
  --input-standard-error-icon-size: var(--icon-size-small);
  --input-standard-error-text-font-size: var(--text-font-size-standard);

  /* Large Radio Input */
  --input-radio-large-height: 2rem;
  --input-radio-standard-height: 1.5rem;

  /* Standard Checkbox Input */
  --input-checkbox-standard-height: 1.5rem;
  --input-checkbox-standard-square: 1.5rem;
  --input-checkbox-standard-label-spacing: 0.5rem;
  --input-checkbox-standard-border-width: 2px;
  --input-checkbox-standard-border-radius: 0.25rem;

  //  Färger
  --color-blue-dark: #{$color-blue-dark};
  --color-blue-standard: #{$color-blue-standard};
  --color-blue-light: #{$color-blue-light};
  --color-red-standard: #{$color-red-standard};
  --color-red-light: #{$color-red-light};
  --color-green-standard: #{$color-green-standard};

  --color-gray-darker: #{$color-gray-darker};
  --color-gray-dark: #{$color-gray-dark};
  --color-gray-standard: #{$color-gray-standard};
  --color-gray-light: #{$color-gray-light};
  --color-gray-lighter: #{$color-gray-lighter};
  --color-gray-lightest: #{$color-gray-lightest};
  --color-white: #{$color-white};
  --color-blue-marin: #{$color-blue-marin};
  --color-blue-marin-25: #{$color-blue-marin-25};

  --color-blue-gradient: linear-gradient(45deg, var(--color-blue-standard), var(--color-blue-light));

  --color-red-gradient: linear-gradient(45deg, var(--color-red-standard), var(--color-red-light));

  --input-horizontal-padding-large: 1rem;

  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-normal: 400;

  --text-decoration-a: 0.03rem;

  --hero-image-width: 100%;
  --hero-image-height: 100%;

  --bubble-image-width: 13rem;
  --bubble-image-height: 13rem;
  --bubble-image-margin-bottom: 1rem;

  --gap: 1rem;
  --gap-large: 1.5rem;
  --gap-Xlarge: 2rem;
  --gap-XXlarge: 2.5rem;

  --image-container-radius: 1rem;

  --cards-modals-corner-radius: var(--rounded);
  --cards-modals-padding: var(--spacing-2xl);
  --cards-modals-gap: var(--spacing-xl);
}

@media screen and (max-width: $breakpoint-m) {
  :root {
    --logo-height: 2rem;
    --heading-font-size-large: 2.5rem;

    --spacing-side-margin: 2rem;

    --spacing-vertical-large-between-sections: 2rem;

    --header-height: 4.5rem;
    --header-width: 100%;
    --header-margin-top: 0rem;

    --homepage-intro-vertical-spacing-top: 8rem;
    --homepage-intro-vertical-spacing-header: 4rem;
  }
}

@media screen and (max-width: $breakpoint-s) {
  :root {
    --spacing-gutter-size: 1rem;
    --spacing-sidehead: 1rem;
    --spacing-vertical-standard: 1rem;

    --button-height-standard: 2rem;
    --button-padding-standard: 1.25rem;
    --button-height-large: 2.5rem;
    --button-padding-large: 1.5rem;
    --icon-button-padding-standard: 0.75rem;
    --icon-button-padding-large: 0.75rem;

    --heading-font-size-small: 1.5rem;

    --homepage-intro-text-block-width: calc((100% / 12) * 6);

    --homepage-post-cloud-block-left-margin: calc((100% / 12) * 6);

    --homepage-round-entrance-text-area-width: calc((100% / 12) * 6);
    --homepage-round-entrance-text-area-width: calc((100% / 12) * 6);
    --homepage-round-entrance-text-area-left-margin: 0;
    --homepage-round-entrance-text-area-right-margin: 0;

    --overlay-width: calc((100% / 12) * 12);
    --overlay-width-left-margin: 0;

    --header-height: 4rem;
    --header-margin-right: 1rem;
    --header-margin-left: 1rem;
    --header-padding-left: 1rem;
    --header-padding-right: 1rem;

    --icon-button-size-standard: var(--icon-size-small);
    --icon-button-size-large: var(--icon-size-medium);
    --icon-button-size-xlarge: var(--icon-size-large);
  }
}

@media screen and (max-width: $breakpoint-xs) {
  :root {
    font-size: 14px;

    --homepage-round-entrance-text-area-width: calc((100% / 12) * 4);
    --homepage-round-entrance-text-area-width: calc((100% / 12) * 4);

    --overlay-width: calc((100% / 12) * 4);
    --overlay-margin-top: 0rem;
    --overlay-border-radius: 0rem;

    --input-radio-large-height: 2.75rem;
  }
}
