@use 'styles/variables' as *;

.row {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-l);
}
.column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

.tight {
  gap: var(--spacing-m);
}
.wrap {
  flex-wrap: wrap;
}
.hidemobile {
  @media screen and (max-width: $breakpoint-s) {
    display: none !important;
  }
}
.hidedesktop {
  @media screen and (min-width: $breakpoint-s) {
    display: none !important;
  }
}

.fit {
  width: 100%;
}
.child {
  flex-basis: 50%;
  width: 50%;
}
