@use 'styles/variables' as *;

.wrapper {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 14rem;
}

.hidedesktop {
  @media screen and (min-width: $breakpoint-s) {
    display: none;
  }
}

.hidemobile {
  @media screen and (max-width: $breakpoint-s) {
    display: none;
  }
}

.buttons {
  margin-top: 1em;

  button {
    margin-right: 10px;
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .wrapper {
    .lineBreak {
      white-space: normal;
    }

    h1 {
      font-size: 28px;
    }

    .wrapper {
      width: 100%;
      gap: var(--spacing-l);
    }
  }
}

@media screen and (min-width: $breakpoint-s) {
  .lineBreak {
    white-space: pre-line;
    line-height: 1.2em;
  }
}
