@use '../../../../styles/variables' as *;

//TODO: Fix styling
body {
  .uploadsmallimg {
    display: none;
  }

  .imgboxes {
    display: flex;
    flex-wrap: wrap;
    gap: var(--gap-XXlarge);
    margin-top: var(--form-vertical-spacing);

    .uploadedimgbox {
      flex-shrink: 0;
      position: relative;
      @media screen and (max-width: $breakpoint-s) {
        cursor: pointer;
      }
      button {
        position: absolute;
        top: var(--spacing-s);
        right: var(--spacing-s);
        display: none;
      }
      &:hover button {
        display: flex;
      }
      img {
        object-fit: fill;
        height: 15rem;
        border-radius: var(--rounded);
      }
    }

    .addimgbutton {
      flex-shrink: 0;
      height: 15rem;
      width: 10rem;
      background: var(--color-gray-lightest);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--image-container-radius);
      border: var(--border-standard) var(--color-blue-dark);
      color: var(--color-blue-dark);
      cursor: pointer;

      &:hover {
        box-shadow: var(--shadow-standard);
      }

      svg {
        width: var(--button-height-large);
        height: var(--button-height-large);
        justify-content: center;
      }
    }
  }
}
