@use '../../styles/variables' as *;

.main {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
}

.mobileprofile {
  display: flex;
  border-bottom: var(--border-thin) var(--color-gray-light);
  padding-bottom: var(--spacing-xl);
  gap: var(--spacing-l);
  align-items: center;
  color: var(--color-gray-darkest) !important;
  .avatar {
    width: 5rem;
    height: 5rem;
    border-radius: var(--rounded-full);
    overflow: hidden;
    img {
      width: 5rem;
      height: 5rem;
    }
  }
  .profileinfo {
    max-width: calc(100% - 6rem);
    h2 {
      margin-bottom: var(--spacing-s);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.readmore {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.mobilecontact {
  position: fixed;
  bottom: var(--spacing-2xl);
  right: var(--spacing-2xl);
  a {
    box-shadow: var(--shadow-standard);
  }
}
.categorycontainer {
  display: flex;
  gap: var(--gap);
  flex-wrap: wrap;
}

.imageScrollContainer {
  position: relative;
}

.imagecontainer {
  display: flex;
  gap: var(--gap-XXlarge);
  overflow-x: auto;
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Internet Explorer and Edge
  &::-webkit-scrollbar {
    // For Google Chrome, Safari and Opera
    display: none;
  }

  img {
    min-height: 12rem;
    height: 12rem;
    width: auto;
    border-radius: var(--rounded);
  }
}

.scrollbutton,
.scrollbuttonleft {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border-radius: var(--rounded-full);
  background: var(--color-white);
  width: var(--button-height-large);
  height: var(--button-height-large);
  box-shadow: 0px 8px 31px 0px rgba(186, 186, 186, 0.25);
  color: var(--color-blue-dark);
}
.scrollbutton {
  right: 0;
}
.scrollbuttonleft {
  left: 0;
}

ul.infolist {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: var(--spacing-l);
  li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &.fullcolumn {
      flex-direction: column;
    }
  }
}

.editBtnsContainer {
  display: flex;
  gap: var(--spacing-l);
  justify-content: center;
}

@media screen and (max-width: $breakpoint-xs) {
  .smallcard {
    box-shadow: none !important;
    padding-top: 0;
  }
  .button .iconLeft {
    padding-right: var(--button-padding-standard);
    padding-left: var(--icon-button-padding-standard);
  }

  .categorycontainer {
    padding-top: var(--spacing-m);
    padding-bottom: var(--spacing-m);
  }
  .imageScrollContainer {
    padding-bottom: var(--spacing-s);
  }
  .aboutText {
    border-bottom: var(--border-thin) var(--color-gray-light);
    padding-bottom: var(--spacing-xl);
    p {
      font-size: var(--heading-font-);
    }
    &.closed p {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @supports (-webkit-line-clamp: 5) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
  }
  .scrollbutton {
    right: -12px !important;
  }
  .scrollbuttonleft {
    left: -12px !important;
  }
  .aside {
    padding-bottom: var(--spacing-3xl);
  }
}
