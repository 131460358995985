@use 'styles/variables' as *;

$tagColors: (
  blue: $color-blue-standard,
  red: $color-red-standard,
  darkblue: $color-blue-dark,
);

@mixin solidTag() {
  @each $name, $color in $tagColors {
    &.#{$name} {
      color: $color;
      background-color: rgba($color, 0.1);
    }
  }
}
@mixin outlinedTag() {
  @each $name, $color in $tagColors {
    &.#{$name} {
      color: $color-gray-darker;
      background-color: transparent;
      border: var(--border-standard) #{$color};
    }
  }
}

.tag {
  display: flex;
  box-sizing: border-box;
  border-radius: var(--rounded-full);
  align-items: center;
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  gap: var(--spacing-xs);
  height: 2rem;
  font-size: var(--text-font-size-standard);
  font-weight: var(--font-weight-bold);
  &.button:hover {
    text-decoration: underline;
  }
  .deletebutton {
    cursor: pointer;
    margin-right: calc(var(--spacing-xs) * -1);
  }
  &.large {
    padding-left: var(--spacing-l);
    padding-right: var(--spacing-l);
    height: 2.5rem;
    font-size: var(--text-font-size-large);
    gap: var(--spacing-s);
    .deletebutton {
      margin-right: calc(var(--spacing-s) * -1);
    }
  }
  &.small {
    padding-left: var(--spacing-m);
    padding-right: var(--spacing-m);
    height: 1.75rem;
    gap: var(--spacing-xs);
    font-size: var(--text-font-size-small);
  }

  &.solid {
    @include solidTag();
  }

  &.outlined {
    @include outlinedTag();
  }
  &.button {
    cursor: pointer;
  }
}
