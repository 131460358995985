@use 'styles/variables' as *;

.infoSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue-dark);
  height: 40vh;
  padding: var(--spacing-4xl) var(--spacing-m);
  text-align: center;
  color: var(--color-white);

  @media screen and (max-width: $breakpoint-m) {
    height: 20vh;
    padding: var(--spacing-3xl) var(--spacing-3xl);
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-size: var(--heading-font-size-standard);
  margin-bottom: var(--spacing-l);

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--heading-font-size-small);
  }

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--heading-font-size-small);
  }
}

.subtitle {
  font-size: var(--text-font-size-standard);
  margin-bottom: var(--spacing-4xl);
  color: var(--color-gray-lighter);

  @media screen and (max-width: $breakpoint-m) {
    font-size: var(--text-font-size-small);
    margin-bottom: var(--spacing-3xl);
  }

  @media screen and (max-width: $breakpoint-xs) {
    font-size: var(--text-font-size-small);
    margin-top: var(--spacing-l);
  }
}

.buttonwrapper {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-l);
  padding-top: var(--spacing-3xl);

  @media screen and (max-width: $breakpoint-m) {
    gap: var(--spacing-s);
    padding-top: var(--spacing-xl);
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding-top: var(--spacing-s);
  }
}
