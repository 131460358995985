@use '../../styles/variables' as *;

.hideonmedium {
  @media screen and (max-width: $breakpoint-m) {
    display: none !important;
  }
}
.hideonmediumup {
  @media screen and (min-width: $breakpoint-m) {
    display: none !important;
  }
}
.hideonsmall {
  @media screen and (max-width: $breakpoint-s) {
    display: none !important;
  }
}
.hideonsmallup {
  @media screen and (min-width: $breakpoint-s) {
    display: none !important;
  }
}
.hideonxsmall {
  @media screen and (max-width: $breakpoint-xs) {
    display: none !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @media screen and (max-width: $breakpoint-xs) {
    .footerLogo {
      display: flex;
      flex-direction: column;
    }
    .footerLinks {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Two columns taking 50% each */
      gap: var(--spacing-l);
      width: 100%;
      align-items: start;
      justify-items: center;
      justify-content: center;
    }
    .footerCol {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-xl);

      ul {
        list-style: none;
        margin-bottom: 5px;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      h4 {
        font-size: 16px;
        color: #000000bb;
      }
      li {
        margin-bottom: 5px;
      }
      button,
      a {
        flex-shrink: 0;
        color: var(--color-gray-darker);
        font-size: 1.125rem;
      }

      h3 {
        font-size: 1.2rem;
        margin-bottom: 0;
      }

      .topFooter {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
    .bottomFooter {
      width: 100%;
      box-sizing: border-box;
      border-top: 1px #c6c6c7 solid;
      display: flex;
      flex-direction: column;
      padding-top: var(--spacing-l);
      padding-bottom: var(--spacing-2xl);

      text-align: center;
    }
    .logo {
      width: auto;
      height: var(--logo-height);
    }
    span {
      font-size: var(--text-font-size-large);
    }
  }

  @media screen and (max-width: $breakpoint-s) {
    .footer {
      display: flex;
      box-sizing: border-box;
      width: 100%; /* Adjusted to 100% for smaller screens */
      padding-top: var(--spacing-3xl);
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xl);
    }
    .footerLogo {
      display: flex;
      flex-direction: column;
    }
    .rightColumn {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-basis: auto;
    }
    .topFooter {
      width: 90%;
      padding: var(--spacing-2xl) var(--spacing-l);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xl);
    }
  }
  @media screen and (min-width: $breakpoint-s) {
    padding: var(--spacing-vertical-standard) var(--spacing-side-margin);

    .footer {
      display: flex;
      box-sizing: border-box;
      width: var(--footer-width);
      padding-left: var(--footer-padding-left);
      padding-right: var(--footer-padding-right);
      padding-top: 6rem;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xl);
      background-color: var(--color-white);
      border-radius: 12px;
      box-shadow: var(--footer-box-shadow);
    }
    .footerLogo {
      display: flex;
      flex-direction: column;
      width: 30%;
    }
    .rightColumn {
      display: flex;
      width: 65%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      flex-basis: auto;
      margin-right: 2em;
    }
    a {
      flex-shrink: 0;
      color: var(--color-gray-darker);
      font-size: 1.125rem;
    }
    span {
      font-size: var(--text-font-size-large);
    }
  }

  @media screen and (min-width: $breakpoint-xs) {
    .leftColumn {
      display: flex;
      width: 20%;
      flex-direction: column;
      margin-left: 2px;
      gap: var(--spacing-l);
    }

    .footerLinks {
      display: grid;
      grid-template-columns: repeat(4, 1fr); /* Four columns */
      gap: var(--spacing-l);
      width: 100%;
      align-items: start;
      justify-items: start;
    }

    .bottomFooter {
      width: 100%;
      box-sizing: border-box;
      border-top: 1px #c6c6c7 solid;
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      padding-top: var(--spacing-xl);
      padding-bottom: 2rem;
      padding-left: 20px;
    }
    .footerCol {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-l);

      ul {
        list-style: none;
        margin-bottom: 5px;
        padding: 0;
      }
      h4 {
        font-size: 1.125rem;
        color: #000000bb;
      }
      li {
        margin-bottom: 5px;
      }
      button,
      a {
        flex-shrink: 0;
        color: var(--color-gray-darker);
        font-size: 1.125rem;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      h3 {
        font-size: 1rem;
        margin-bottom: 0;
      }
    }
    .topFooter {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .logo {
      width: auto;
      height: var(--logo-height);
    }

    span {
      font-size: var(--text-font-size-large);
    }
  }

  @media screen and (max-width: $breakpoint-m) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @media screen and (min-width: $breakpoint-m) {
    .topFooter {
      width: 90%;
      padding: var(--spacing-2xl) var(--spacing-l);
      display: flex;
      flex-direction: row;
      gap: var(--spacing-xl);
    }
  }
}
