@use '../../../styles/variables' as *;

.header {
  padding: 0 0 var(--spacing-m) 0;
  border-bottom: var(--border);
  margin-bottom: var(--spacing-2xl);
}

.container {
  display: flex;
  flex-direction: column;
}

.nextstep {
  display: flex;
  flex-direction: row;
  margin-top: var(--form-vertical-spacing);
  align-items: center;
  font-weight: var(--font-weight-bold);
  justify-content: space-between;
}
