@use 'styles/variables' as *;

@mixin colspans($suffix: '') {
  @each $offset in $columns {
    &-#{$offset} {
      @each $span in $columns {
        &-#{$span}#{$suffix} {
          grid-column: #{$offset + 1} / span #{$span};
        }
      }
    }
  }
}

:global {
  @mixin colstartspan($label: '') {
    @for $i from 1 through 12 {
      @for $y from 1 through 12 {
        .col-start#{$label}-#{$y} {
          grid-column: #{$y};
          &.col-span#{$label}-#{$i} {
            grid-column: #{$y} / span #{$i};
          }
        }
      }
      .col-span#{$label}-#{$i} {
        grid-column-start: span #{$i};
      }
    }
  }

  @include colstartspan();

  @each $label, $breakpoint in $breakpoints {
    @media (max-width: #{$breakpoint}) {
      @include colstartspan(-#{$label});
    }
  }

  .gridContainer {
    display: grid;
    box-sizing: border-box;
    width: 100%;
    padding-left: var(--spacing-side-margin);
    padding-right: var(--spacing-side-margin);
    grid-template-columns: repeat(12, 1fr);
    margin-right: auto;
    margin-left: auto;
    max-width: 1500px;
    grid-auto-rows: auto;
    gap: var(--spacing-2xl);

    @media screen and (max-width: $breakpoint-xs) {
      grid-template-columns: repeat(3, 1fr);
    }

    &.formgrid {
      column-gap: var(--form-horizontal-input-spacing);
      row-gap: var(--form-vertical-input-spacing);
    }
  }

  .flexrow {
    gap: var(--spacing-l);
    display: flex;
    flex-direction: row;
  }
  .flexcolumn {
    gap: var(--spacing-l);
    display: flex;
    flex-direction: column;
  }

  .gridColumn {
    &.flexrow {
      gap: var(--spacing-2xl);
    }
    &.flexcolumn {
      gap: var(--spacing-2xl);
      display: flex;
      flex-direction: column;
    }
  }

  .gridContainer .gridContainer {
    padding-left: 0;
    padding-right: 0;
  }
}
