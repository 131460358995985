@use '../../../styles/variables' as *;

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6%;
  margin-top: 2rem;

  span {
    margin-right: 8px;
  }

  a:hover {
    cursor: pointer;
  }
}
@media screen and (max-width: $breakpoint-s) {
  .title {
    font-size: var(--heading-font-size-small);
    font-family: $font-family-cocon-regular;
    color: var(--color-red-standard);
  }
}
