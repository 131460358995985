.container {
  position: relative;
  .editbutton {
    visibility: hidden;
    position: absolute;
    top: var(--spacing-l);
    right: var(--spacing-l);
  }
  &:hover {
    .editbutton {
      visibility: visible;
    }
  }
}
